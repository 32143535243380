import React, { useState } from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from '../../../components/helper'
import { Link } from "react-router-dom";

const Currency = ({
  isFavorite,
  search,
  setSearch,
  activeColumn,
  toggleFavorite,
  activeTab,
  handleSort,
  filteredData,
  sortMarketNav,
  socketMarketData,
  handleTabClick,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.currency}>
      <div className={styles.stickyHeaderNav}>
        <div className={styles.nav}>
          {socketMarketData?.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
            >
              {x.currency}
            </button>
          ))}
        </div>
        <form className={styles.form} action="" onSubmit={(e) => {
          e.preventDefault();
        }}>
          <input
            className={styles.input}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            name="search"
            placeholder="Search by pair"
            required
          />
          <button className={styles.result}>
            <Icon name="search" size="20" />
          </button>
        </form>
        <div className={styles.foot}>
          <div className={styles.nav}>
            {sortMarketNav?.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: activeTab === x,
                })}
                onClick={() => {
                  handleTabClick(x);
                }}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.customRow}>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn?.key === "name" })}
                onClick={() => { handleSort("name", filteredData) }}>
                Pair
              </div>
            </div>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn?.key === "price" })}
                onClick={() => { handleSort("price", filteredData) }}>
                Price
              </div>
            </div>
            <div className={styles.customCol}>
              <div className={cn("sorting", { [styles.activeColumn]: activeColumn?.key === "dayChange" })}
                onClick={() => { handleSort("dayChange", filteredData) }}>
                Change
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.table}>
        {/* <div className={styles.col}>
          <div >Pair</div>
        </div>
        <div className={styles.col}>
          <div >Price</div>
        </div>
        <div className={styles.col}>
          <div>Change</div>
        </div> */}
        {
          // marketLoading ? <ExchangeSkeleton rowCount={22} colCount={3} />
          //   :
          // allMarketsData?.data?.filter(x => x.currencyId === currencyId).map((x, index) => {
          // return x.markets?.map((x, count) => {
          filteredData?.map((x, index) => {
            return (search !== "" && x?.slug?.replace("-", "/")?.toLowerCase()?.indexOf(search?.toLowerCase()) === -1) ? null :
              <div className={styles.row} key={index}>
                <div className={styles.col}>
                  <div className={styles.line}>
                    <button
                      className={cn(styles.favoriteLine, {
                        [styles.active]: filteredData?.includes(x?.id),
                      })}
                      onClick={() => toggleFavorite(x, index)}
                    >
                      {isFavorite(x.id) ? (
                        <Icon name="star" size="16" fill="#22767d" />
                      ) : (
                        <Icon name="star-outline" size="16" />
                      )}
                    </button>
                  </div>
                  <div className={styles.line}>
                    <Link to={`/exchange/${x.slug}`} className={styles.market}>
                      <div className={styles.info}>
                        {x?.slug?.replace("-", "/")?.toUpperCase()}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className={styles.col}>  {getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}</div>
                <div className={styles.col}>
                  {parseFloat(x.dayChange) < 0 ? (
                    <div className={styles.negative}>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
                  ) : (
                    <div className={styles.positive}><small>+</small>{parseFloat(x.dayChange).toFixed(2)}<small>%</small></div>
                  )
                  }
                </div>
              </div>
            // })
          })}
      </div>
    </div>
  );
};

export default Currency;
