import React, { useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import { Link } from "react-router-dom";
import Dropdown from "../../../../components/Dropdown";
import { getDigitsAfterDecimal, getPostiveNegativeNum } from "../../../../components/helper";
import PanelSkeleton from "../../../../components/Skeleton/PanelSkeleton";

const navigation = [];

const Panel = ({
  activeIndex,
  setActiveIndex,
  loading,
  socketMarketData,
}) => {
  const [sorting, setSorting] = useState(navigation[0]);

  return (
    <div className={styles.panel}>
      <div className={styles.body}>
        <div className={styles.list}>
          {loading ? <PanelSkeleton rowCount={3} />
            :
            socketMarketData?.map((x, index) => {
              return x?.markets?.slice(0, 3)?.map((i, count) => (
                <Link className={styles.item} key={count}
                  to={localStorage.getItem("signature") ? `/exchange/${i.slug}` : "/sign-up"}
                >
                  <div className={styles.icon}>
                    <img src={i.icon} alt="Currency" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.line}>
                      <div className={styles.title}>{i.slug}</div>
                      {getPostiveNegativeNum(i.dayChange) ?
                        <div className={styles.positive}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                        :
                        <div className={styles.negative}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                      }
                    </div>
                    <div className={styles.price}>{getDigitsAfterDecimal(i.currentMarketPrice, i.priceDecimalPrecision)}</div>
                  </div>
                </Link>
              ))
            })}
        </div>
      </div>
      <div className={styles.foot}>
        <div className={styles.nav}>
          {socketMarketData?.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: x.currencyId === activeIndex,
              })}
              onClick={() => {
                setActiveIndex(x.currencyId);
              }}
              key={index}
            >
              {x.currency}
            </button>
          ))}
        </div>
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to={localStorage.getItem("signature") ? "/exchange/BTC-EUR" : "/sign-up"}
        >
          Trade
        </Link>
        <Dropdown
          className={styles.dropdown}
          value={sorting}
          setValue={setSorting}
          options={navigation}
        />
      </div>
    </div>
  );
};
export default Panel;