import React, { useState } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Panel from "./Panel";
import Trade from "./Trade";

const Details = ({
  loading,
  isFavorite,
  search,
  setSearch,
  activeColumn,
  toggleFavorite,
  activeTab,
  handleSort,
  filteredData,
  sortMarketNav,
  socketMarketData,
  handleTabClick
}) => {
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <div className={cn("section", styles.details)}>
      <div className={cn("container", styles.container)}>
        <Panel
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          loading={loading}
          socketMarketData={socketMarketData}
        />
        <Trade
          loading={loading}
          isFavorite={isFavorite}
          search={search}
          setSearch={setSearch}
          activeColumn={activeColumn}
          toggleFavorite={toggleFavorite}
          activeTab={activeTab}
          handleSort={handleSort}
          filteredData={filteredData}
          sortMarketNav={sortMarketNav}
          handleTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default Details;
