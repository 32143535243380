import { combineReducers } from "redux";
import exchangeReducer from './exchangeReducer'
import getCoinsReducer from './getCoinsReducer';
import newOrderReducer from './newOrderReducer';
import depositFiatVerifyPaymentReducer from './depositFiatVerifyPaymentReducer';
import getUserStatusReducer from './getUserStatusReducer';
import marketsReducer from './marketsReducer';

const appReducer = combineReducers({
  exchange: exchangeReducer,
  coins: getCoinsReducer,
  newOrder: newOrderReducer,
  depositFiatVerifyPayment: depositFiatVerifyPaymentReducer,
  getUserStatus: getUserStatusReducer,
  markets: marketsReducer,
});

const rootReducer = (state, action) => {
  if (action.type == 'CLEAR_STATE') {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;