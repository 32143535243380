import React, { useRef, useEffect } from "react";
import styles from "./index.module.sass";
import { widget } from "../../charting_library/charting_library.esm";
import Datafeed from "./dataFeed";

import {
	DISABLED_FEATURES,
	ENABLED_FEATURES,
	TIME_FRAMES,
} from "./chartConfig";

function getLanguageFromURL() {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	const results = regex.exec(window.location.search);
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, " "));
}

const TVChartContainer = ({ symbol, currentMarketDetails, theme }) => {
	const chartContainerRef = useRef(null);
	const tvWidgetRef = useRef(null);

	useEffect(() => {
		// Initialize the widget only if it doesn't exist
		setTimeout(() => {
			if (!tvWidgetRef.current) {
				const widgetOptions = {
					symbol: `Bitdenex:${symbol.toLowerCase()}`,
					datafeed: Datafeed,
					container: chartContainerRef.current,
					theme: theme,
					library_path: "/charting_library/",
					locale: getLanguageFromURL() || "en",
					fillBackground: true,
					interval: process.env.REACT_APP_CHARTS_INTERVAL || "4H",
					fullscreen: false,
					autosize: true,
					time_frames: TIME_FRAMES,
					enabled_features: ENABLED_FEATURES,
					disabled_features: DISABLED_FEATURES,
					toolbar: false,
					// custom_formatters: {
					// 	priceFormatterFactory: (symbolInfo) => {
					// 		if (symbolInfo === null) {
					// 			return null;
					// 		}
					// 		if (symbolInfo.type === "crypto") {
					// 			return {
					// 				format: (price) =>
					// 					price.toFixed(currentMarketDetails[0]?.priceDecimalPrecision),
					// 			};
					// 		}
					// 		return null;
					// 	},
					// },
				};

				tvWidgetRef.current = new widget(widgetOptions);

				tvWidgetRef.current.onChartReady(() => {
					tvWidgetRef.current
						.activeChart()
						.createStudy("Moving Average", false, false, { length: 10 }, { "plot.color.0": "#9b59b6", "showLabelsOnPriceScale": false });
					tvWidgetRef.current
						.activeChart()
						.createStudy("Moving Average", false, false, { length: 30 }, { "plot.color.0": "#d35400", "showLabelsOnPriceScale": false });
					tvWidgetRef.current
						.activeChart()
						.createStudy("Moving Average", false, false, { length: 99 }, { "plot.color.0": "#00cec9", "showLabelsOnPriceScale": false });
				
						//Remove "Volume" study if it exists
						const studies = tvWidgetRef.current.activeChart().getAllStudies();
						for (let i = 0; i < studies.length; i++) {
							const study = studies[i];
							if (study.name === 'Volume') {
								tvWidgetRef.current.activeChart().removeEntity(study.id);
								break;
							}
						}
				
					});

			} else {
				// Update the symbol if the widget already exists
				tvWidgetRef.current.setSymbol(`Bitdenex:${symbol.toLowerCase()}`, process.env.REACT_APP_CHARTS_INTERVAL);
				
			}
		}, 1000);


	}, [symbol, currentMarketDetails]);

	useEffect(() => {
		// Apply theme changes dynamically
		if (tvWidgetRef.current) {
			tvWidgetRef.current.applyOverrides({
				"paneProperties.background": theme === "dark" ? "#131722" : "#FFFFFF",
			});
		}
	}, [theme]);

	useEffect(() => {
		return () => {
			if (tvWidgetRef.current !== null) {
				tvWidgetRef.current.remove();
				tvWidgetRef.current = null;
			}
		};
	}, []);

	return (
		<>
			<header className={styles.VersionHeader}></header>
			<div ref={chartContainerRef} className={styles.TVChartContainer} />
		</>
	);
};

export default TVChartContainer;