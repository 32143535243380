import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Exchange.module.sass";
import Main from "./Main";
import Balance from "./Balance";
import Currency from "./Currency";
import Table from "./Table";
import Actions from "./Actions";
import Charts from "./Charts";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
// import { io } from 'socket.io-client';
import Successfully from "./Successfully";
import Modal from '../../components/Modal'
import { addNotification } from "../../components/Notification";
import requestHandler from "../../actions/httpClient";
import { userStatusCreator } from "../../actions/getUserStatus";
import { socket } from "../../socket";
import MarketScroller from "../../components/MarketScroller";
import { socketDataReceived } from "../../actions/markets";

const navigation = ["Chart", "Order books", "Trades"];
const sortMarketNav = ["All", "Favorites"];

const Exchange = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [marketLoading, setMarketLoading] = useState(false)
  const [cancelOrder, setCancelOrder] = useState([])
  const [socketData, setSocketData] = useState([])

  const [buyOrdersData, setBuyOrdersData] = useState([])
  const [sellOrdersData, setSellOrdersData] = useState([])

  const [marketTrades, setMarketTrades] = useState([])
  const [currentMarketDetails, setCurrentMarketDetails] = useState([])
  const [openOrders, setOpenOrders] = useState([])
  const [exchangeData, setExchangeData] = useState([])

  const [orderActionResponse, setOrderActionResponse] = useState([])
  const [allMarketsData, setAllMarketsData] = useState([]);
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const [currentMarketPrice, setCurrentMarketPrice] = useState();
  const [dataMarkets, setDataMarkets] = useState([]);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false)
  const [scrollerMarkets, setScrollerMarkets] = useState([]);
  const [flag, setFlag] = useState(false);
  const [favoriteMarkets, setFavoriteMarkets] = useState(userStatus?.userFavMarkets?.length > 0 ? userStatus?.userFavMarkets : []);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState({ column: null, order: 'ASC' });
  const [activeColumn, setActiveColumn] = useState({ key: "", value: false });
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const { socketMarketData } = useSelector((state) => state.markets);

  useEffect(() => {
    if (socketMarketData?.length && activeTab === "All" && activeColumn.key === "") {
      const sortedMarkets = socketMarketData?.[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
      setFilteredData(sortedMarkets);
    }
  }, [socketMarketData]);

  useEffect(() => {
    if (userStatus.length === 0) {
      dispatch(userStatusCreator())
    }

    if (userStatus?.userFavMarkets > 0) {
      setFavoriteMarkets(userStatus?.userFavMarkets);
    }

  }, [userStatus]);

  useEffect(() => {
    createOrdersData(slug);
    socket.connect();
    // sockets connection                                                                                                         
    socket.on("connect", () => { });

    socket.on('orderBook_' + slug.toLowerCase(), function (order) {
      sortOrdersData(order)
    });
    socket.on('orderBookPrice_' + slug.toLowerCase(), function (order) {
      setCurrentMarketPrice(order);
    });

    return () => {
      socket.off('orderBook_' + slug.toLowerCase());
      socket.off('orderBookPrice_' + slug.toLowerCase());
      socket.disconnect();
    }
  }, [slug, orderActionResponse])

  const getRegion = async () => {
    try {
      const regionPayload = await requestHandler("region", "post");
      for (let i = 0; i < regionPayload.data?.data.length; i++) {
        if (regionPayload?.data?.data[i].slug == process.env.REACT_APP_EUROPE_MARKETS) {
          getData(regionPayload?.data?.data[i]?.id);
        }
      }
    }
    catch (e) {
    };
  };

  const createOrdersData = async (slug) => {
    setCurrentMarketPrice();
    try {
      setLoading(true);
      const formData = {
        market_slug: slug,
        signature: localStorage.getItem("signature")
      }
      const ordersDataPayload = await requestHandler("getOpenOrders", "post", formData, 'jwt_token');
      setExchangeData(ordersDataPayload)

      setMarketTrades(ordersDataPayload.data.data.market_trades)
      setOpenOrders(ordersDataPayload.data.data.open_order)
      setCurrentMarketDetails(ordersDataPayload.data.data.current_market_details)

      setBuyOrdersData(createOrderbook(ordersDataPayload.data.data.buy))
      setSellOrdersData(createOrderbook(ordersDataPayload.data.data.sell))

      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  }

  const createOrderbook = (orders) => {
    let createOrders = [];
    let tempData = [];
    for (let order of orders) {
      if (tempData.indexOf(parseFloat(order.fiatPrice)) < 0) {
        createOrders.push(order)
        tempData.push(parseFloat(order.fiatPrice));
      } else {
        let index = tempData.indexOf(parseFloat(order.fiatPrice));
        createOrders[index].remcrypto = parseFloat(order.remcrypto) + parseFloat(createOrders[index].remcrypto);
        createOrders[index].remfiat = parseFloat(order.remfiat) + parseFloat(createOrders[index].remfiat);
      }
    }
    return createOrders;
  }

  const sortOrdersData = (order) => {
    if (order && order.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER)) {
      if (order.orderAction === "new_order")
        setBuyOrdersData(buyData => createOrderbook([...buyData.filter(buyOrder => buyOrder.orderId !== order.orderId), order]));
      else
        setBuyOrdersData(buyData => createOrderbook([...buyData.filter(buyOrder => buyOrder.orderId !== order.orderId)]));
    } else {
      if (order.orderAction === "new_order")
        setSellOrdersData(sellData => createOrderbook([...sellData.filter(sellOrder => sellOrder.orderId !== order.orderId), order]));
      else
        setSellOrdersData(sellData => createOrderbook([...sellData.filter(sellOrder => sellOrder.orderId !== order.orderId)]));
    }
  }

  const getData = async (id) => {
    try {
      setMarketLoading(true);
      let data = {
        type: "allMarkets",
        region: id,
        signature: localStorage.getItem('signature')
      };
      const marketsDataPayload = await requestHandler("get_markets", "post", data, 'jwt_token');
      setScrollerMarkets(marketsDataPayload?.data?.data[0]?.markets);
      // setMarketLoading(false);
      // const sortedMarkets = marketsDataPayload?.data?.data[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
      // setAllMarketsData(marketsDataPayload?.data);
      // setDataMarkets(sortedMarkets);
    }
    catch (e) {
      setMarketLoading(false);
    };
  }

  const orderHandler = (x) => {
    setCancelOrder(x);
    setVisible(true)
  }

  const clearSocketData = () => {
    setSocketData(null)
  }

  const orderResponse = (response) => {
    setOrderActionResponse(response)
  }

  useEffect(() => {
    socket.connect();

    socket.on("europe_markets_web", (data) => {
      dispatch(socketDataReceived(data));
    });

    return (() => {
      socket.off("europe_markets_web");
      socket.disconnect();
    });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    filterData(socketMarketData, tab);
  };

  const toggleFavorite = async (item) => {
    setLoading(true);
    let data = {
      signature: localStorage.getItem("signature"),
      marketId: item?.id
    };
    try {
      const favoritePayload = await requestHandler("addRemoveFavMarkets", "post", data, "jwt_token");
      setLoading(false);
      if (favoritePayload?.status === 200) {
        dispatch(userStatusCreator());
        setFavoriteMarkets(prevFavorites => {
          const isFavorite = prevFavorites?.includes(item.id);
          if (isFavorite) {
            const updatedFavorites = prevFavorites?.filter(id => id !== item.id);
            if (activeTab === "Favorites") {
              const updatedFilteredData = socketMarketData?.[0]?.markets?.filter(dataItem => updatedFavorites?.includes(dataItem.id));
              setFilteredData(updatedFilteredData);
            }
            return updatedFavorites;
          }
          else {
            return [...prevFavorites, item?.id];
          }
        });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSort = (columnName, filteredData) => {
    let newSortedData = filteredData
    if (columnName === '') return newSortedData
    if (columnName === "name") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "price") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    else if (columnName === "dayChange") {
      const sorted =
        order && order === "ASC"
          ? filteredData.sort((a, b) =>
            a["dayChange"] > b["dayChange"] ? 1 : -1
          )
          : filteredData.sort((a, b) =>
            a["dayChange"] < b["dayChange"] ? 1 : -1
          );
      newSortedData = sorted;
    }
    const sortingType = order && order === "ASC" ? "DSC" : "ASC";
    setOrder(sortingType);
    setActiveColumn({ key: columnName, value: true });
    setFilteredData(newSortedData);
  };

  const isFavorite = (marketId) => {
    return favoriteMarkets?.includes(marketId);
  };

  const filterData = (data, tab) => {
    let newFilteredData = [];
    switch (tab) {
      case "All":
        newFilteredData = data?.[0]?.markets || [];
        break;
      case "Favorites":
        newFilteredData = data?.[0]?.markets?.filter(item =>
          favoriteMarkets?.includes(item?.id)
        );
        break;
      case "Gainers":
        newFilteredData = data?.[0]?.markets
          ?.filter(item => item?.dayChange > 0)
          ?.sort((a, b) => b?.dayChange - a?.dayChange);
        break;
      case "Losers":
        newFilteredData = data?.[0]?.markets
          ?.filter(item => item?.dayChange < 0)
          ?.sort((a, b) => a?.dayChange - b?.dayChange);
        break;
      case "Volume":
        newFilteredData = data?.[0]?.markets?.sort(
          (a, b) =>
            parseFloat(b?.volume?.replace(/,/g, "")) -
            parseFloat(a?.volume?.replace(/,/g, ""))
        );
        break;
      default:
        break;
    }
    setFilteredData(newFilteredData);
  };

  return (
    <div className={styles.exchange}>
      <div className={styles.marketScroll}>
        {socketMarketData?.length > 0 && (
          <MarketScroller markets={socketMarketData} />
        )}
      </div>
      <div className={cn("containerEx", styles.containerEx)}>
        <Main currentMarketDetails={currentMarketDetails} currentMarketPrice={currentMarketPrice} />
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {isTablet ? (
          <>
            <Actions currentMarketDetails={currentMarketDetails} exchangeData={exchangeData} orderResponse={orderResponse} />
            {activeIndex === 0 && (
              <div className={styles.box}>
                <Charts currentMarketDetails={currentMarketDetails} slugTV={slug} slug={slug.replace("-", "").toUpperCase()} />
                <Table openOrders={openOrders} marketTrades={marketTrades} orderHandler={orderHandler} />
              </div>
            )}
            {activeIndex === 1 && (
              <div className={styles.box}>
                <Balance currentMarketPrice={currentMarketPrice} buy={buyOrdersData} sell={sellOrdersData} currentMarketDetails={currentMarketDetails} socketData={socketData} clearSocketData={clearSocketData} />
              </div>
            )}
            {activeIndex === 2 && (
              <div className={styles.box}>
                <Currency
                  favoriteMarkets={favoriteMarkets}
                  setFavoriteMarkets={setFavoriteMarkets}
                  search={search}
                  setSearch={setSearch}
                  activeColumn={activeColumn}
                  toggleFavorite={toggleFavorite}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  handleSort={handleSort}
                  filteredData={filteredData}
                  sortMarketNav={sortMarketNav}
                  socketMarketData={socketMarketData}
                  handleTabClick={handleTabClick}
                  isFavorite={isFavorite}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.colMarket}>
                <Balance currentMarketPrice={currentMarketPrice} buy={buyOrdersData} sell={sellOrdersData} currentMarketDetails={currentMarketDetails} socketData={socketData} clearSocketData={clearSocketData} loading={loading} />
              </div>
              <div className={styles.col}>
                <Charts currentMarketDetails={currentMarketDetails} slugTV={slug} slug={slug.replace("-", "").toUpperCase()} />
                <Actions currentMarketPrice={currentMarketPrice} currentMarketDetails={currentMarketDetails} exchangeData={exchangeData} orderResponse={orderResponse} />
              </div>
              <div className={styles.col}>
                <Currency
                  favoriteMarkets={favoriteMarkets}
                  setFavoriteMarkets={setFavoriteMarkets}
                  search={search}
                  setSearch={setSearch}
                  activeColumn={activeColumn}
                  toggleFavorite={toggleFavorite}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  handleSort={handleSort}
                  filteredData={filteredData}
                  sortMarketNav={sortMarketNav}
                  socketMarketData={socketMarketData}
                  handleTabClick={handleTabClick}
                  isFavorite={isFavorite}
                />
              </div>
            </div>
            <Table openOrders={openOrders} marketTrades={marketTrades} orderHandler={orderHandler} amountDecimal={currentMarketDetails[0]?.amountDecimalPrecision} priceDecimal={currentMarketDetails[0]?.priceDecimalPrecision} />
          </>
        )}

        <Modal
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <Successfully
            item={cancelOrder}
            cancelOrderLoading={cancelOrderLoading}
            closeModal={() => setVisible(false)}
            cancelOrder={async (orderId) => {
              try {
                setCancelOrderLoading(true)
                const data = {
                  signature: localStorage.getItem("signature"),
                  orderId: orderId
                }
                const payload = await requestHandler('cancelOrder', 'post', data, 'jwt_token')
                if (payload.status === 200) {
                  setCancelOrderLoading(false)
                  addNotification({
                    title: 'success', message: 'Order cancelled successfully'
                    , type: 'success'
                  })
                  dispatch(userStatusCreator())
                  setVisible(false)
                  setOrderActionResponse(payload)
                }


              } catch (error) {
                addNotification({
                  title: 'error', message: error?.data?.message[0]
                  , type: 'danger'
                })
                setVisible(false)
              }

            }
            }

          />
        </Modal>
      </div>
    </div>
  );
};

export default Exchange;
